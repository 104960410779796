import {
  ActiveSubscriptions,
  AdminPanelPage,
  AmsDownloadHistoryTab,
  AmsMacBinding,
  AmsMyCalendar,
  AmsOverview,
  AmsReports,
  AmsShiftsTab,
  AmsUserShiftsTab,
  AnnouncementSettings,
  ArchivedInventoryPage,
  BusinessUnitSettingsAdmin,
  Calendar,
  ClaimsPage,
  ClaimsSettingsHome,
  CompanySettingsAdmin,
  CreateBulkItems,
  CreateBulkUsers,
  CreateQuestion,
  CreationLogs,
  DashboardPage,
  DepartmentsPage,
  DnarsPage,
  DnarsSettingsAdmin,
  DynamicFormSettings,
  EditAutoLeaveApplyRule,
  EditDynamicForm,
  EditHROperationSettings,
  EditOnboardingUser,
  EditProjectPage,
  EditQuestion,
  EditRolesPage,
  EditSlackAnnouncementSetting,
  EditSupportStaffUser,
  EditUser,
  EditWorkflow,
  EditWorkflowCategory,
  EditWorkflowTasksform,
  EmailListSettings,
  FeedbackPage,
  HRDashboardPage,
  HROperationsSettings,
  IdentityDocuments,
  IdentityDocumentSettings,
  ImportDynamicForm,
  IntegrationsPage,
  InventorySettingsAdmin,
  Items,
  Leaves,
  LeavesSettingsAdmin,
  Library,
  MyReviews,
  NewAutoLeaveApplyRuleForm,
  NewCapitalizeItem,
  NewClaimSettingForm,
  NewDynamicForm,
  NewProjectPage,
  NewRolesPage,
  NewSlackAnnouncementSetting,
  NewSupportStaffUser,
  NewTrackedItemPage,
  NewUser,
  NewWorkflow,
  NewWorkflowCategory,
  NewWorkflowTasksform,
  OfficeSettingsAdmin,
  OnboardingUser,
  PMSConfiguration,
  PreOnboardingSettings,
  Profile,
  ProjectHistoryPage,
  Projects,
  ProjectSettingsAdmin,
  ProjectTimeline,
  Question,
  Reports,
  ReviewSettingsAdmin,
  RolesPage,
  Settings,
  ShowProjectPage,
  ShowTaskAttachment,
  ShowTicket,
  ShowWorkflowTasksform,
  SubCategoryPage,
  SubscriptionPanel,
  SubscriptionReports,
  TaskDetailPage,
  TaskTriggers,
  TeamEvaluation,
  Tickets,
  TicketSetting,
  TicketSettings,
  TimeTrackerSettingsAdmin,
  UpdateTicketEmail,
  //TODO: Disabling communication allownance tab, will enable when required
  // UploadInvoice,
  UserProjectHistoryPage,
  UserReviewDetails,
  Users,
  UserTask,
  ViewItemDetailPage,
  ViewUserProbationDetail,
  WorkflowAllTasks,
  WorkflowCategoryDetail,
  WorkFlowCategoryListings
} from './asyncComponents'

const appRoutes = [
  {
    path: '/initial_details',
    component: EditUser,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'people', entity: 'users', action: 'edit_profile' }
  },
  {
    path: '/dashboard',
    sidebarConfig: { routePrefix: '/dashboard', path: '/dashboard' },
    icon: 'dashboard',
    text: 'Dashboard',
    accessConfig: { alwaysEnabled: true },
    component: DashboardPage
  },
  {
    path: '/people',
    sidebarConfig: { routePrefix: '/people', path: '/people' },
    icon: 'people',
    text: 'People',
    children: [
      { path: '/people', indexRoute: true },
      { path: '/people/team-management' },
      { path: '/people/employee-offboarding' }
    ],
    component: Users,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_people' }
  },
  {
    path: '/people/edit/:id',
    notSidebar: true,
    component: EditUser,
    exact: false,
    atLeastOne: true,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: [
      { group: 'people', entity: 'users', action: 'edit_profile' },
      { group: 'people', entity: 'users', action: 'edit_profile_of_others' }
    ]
  },
  {
    path: '/people/:id/:tab',
    sidebarConfig: { routePrefix: '/people', path: '/people' },
    component: Profile,
    notSidebar: true,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_people' }
  },
  {
    path: '/people/:id/:tab/:tab',
    sidebarConfig: { routePrefix: '/people', path: '/people' },
    component: Profile,
    notSidebar: true,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_people' }
  },
  {
    path: '/people/:id',
    sidebarConfig: { routePrefix: '/people', path: '/people' },
    component: Profile,
    notSidebar: true,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_people' }
  },
  {
    path: '/people/new',
    notSidebar: true,
    sidebarConfig: { routePrefix: '/users', path: '/users' },
    component: NewUser,
    accessConfig: { moduleName: 'people' },
    exact: false,
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/people/support-staff/new',
    component: NewSupportStaffUser,
    accessConfig: { moduleName: 'support_staff' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/people/support-staff/edit/:id',
    component: EditSupportStaffUser,
    accessConfig: { moduleName: 'support_staff' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/people/new-bulk-users',
    notSidebar: true,
    component: CreateBulkUsers,
    accessConfig: { moduleName: 'people' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/people/operational-staff/new-bulk-operational-staff',
    component: CreateBulkUsers,
    accessConfig: { moduleName: 'support_staff' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/people/support-staff/:id',
    sidebarConfig: { routePrefix: '/people/support-staff', path: '/people' },
    text: 'Profile',
    component: Profile,
    accessConfig: { moduleName: 'support_staff' },
    exact: true,
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_profile' }
  },
  {
    path: '/hr-dashboard',
    sidebarConfig: { routePrefix: '/hr-dashboard', path: '/hr-dashboard' },
    icon: 'ha_dashboard',
    exact: true,
    text: 'HA Operations',
    children: [
      { path: '/hr-dashboard', indexRoute: true },
      { path: '/hr-dashboard/reviews' },
      { path: '/hr-dashboard/pre-onboardings' },
      { path: '/hr-dashboard/approvals' },
      { path: '/hr-dashboard/verification' }
    ],
    component: HRDashboardPage,
    accessConfig: { alwaysEnabled: true },
    atLeastOne: true,
    requiredPermissions: [
      { group: 'hrDashboard', entity: 'users', action: 'probation_tab' },
      { group: 'hrDashboard', entity: 'users', action: 'review_tab' },
      { group: 'hrDashboard', entity: 'users', action: 'onboarding_tab' },
      { group: 'hrDashboard', entity: 'users', action: 'verification_tab' },
      { group: 'hrDashboard', entity: 'users', action: 'announcement_approval' }
    ]
  },
  {
    path: '/probation/:id',
    component: ViewUserProbationDetail,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'hrDashboard', entity: 'users', action: 'probation_tab' }
  },
  {
    path: '/review/:id',
    component: ViewUserProbationDetail,
    accessConfig: { alwaysEnabled: true },
    exact: true,
    requiredPermissions: { group: 'hrDashboard', entity: 'users', action: 'review_tab' }
  },
  {
    path: '/performance/',
    icon: 'pms',
    text: 'Performance',
    sidebarConfig: {
      routePrefix: '/performance',
      path: '/performance'
    },
    accessConfig: { alwaysEnabled: true },
    atLeastOne: true,
    requiredPermissions: [
      { group: 'pms', entity: 'general', action: 'performance_review_settings' },
      { group: 'pms', entity: 'general', action: 'my_team_tab' },
      { group: 'pms', entity: 'general', action: 'library' }
    ]
  },
  {
    path: '/performance/',
    component: PMSConfiguration,
    children: [{ path: '/performance-management/team' }, { path: '/performance-management/review-settings' }],
    accessConfig: { alwaysEnabled: true },
    exact: true,
    requiredPermissions: { group: 'pms', entity: 'general', action: 'performance_review_settings' }
  },
  {
    path: '/performance/reviews',
    accessConfig: { alwaysEnabled: true },
    component: MyReviews,
    exact: true,
    requiredPermissions: { group: 'pms', entity: 'general', action: 'performance_management_settings' }
  },
  {
    component: TeamEvaluation,
    exact: true,
    accessConfig: { alwaysEnabled: true },
    children: [{ path: '/performance/my-team' }, { path: '/performance/kpi-and-goals' }],
    requiredPermissions: { group: 'pms', entity: 'general', action: 'my_team_tab' }
  },
  {
    path: '/performance/my-team/:id',
    component: UserReviewDetails,
    exact: true,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'pms', entity: 'general', action: 'my_team_tab' }
  },
  {
    path: '/performance/library',
    component: Library,
    exact: true,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'pms', entity: 'general', action: 'library' }
  },
  {
    path: '/profile/:id',
    sidebarConfig: { routePrefix: '/profile', path: '/profile' },
    text: 'Profile',
    component: Profile,
    accessConfig: { alwaysEnabled: true },
    exact: true,
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_profile' }
  },
  {
    path: '/profile/:id/:tab',
    sidebarConfig: { routePrefix: '/profile', path: '/profile' },
    text: 'Profile',
    component: Profile,
    accessConfig: { alwaysEnabled: true },
    exact: true,
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_profile' }
  },
  {
    path: '/profile/:id/:tab/:id',
    sidebarConfig: { routePrefix: '/profile', path: '/profile' },
    text: 'Profile',
    component: Profile,
    accessConfig: { alwaysEnabled: true },
    exact: true,
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_profile' }
  },
  {
    path: '/profile/:id/projects/edit/:id',
    sidebarConfig: { routePrefix: '/profile', path: '/profile' },
    text: 'Profile',
    accessConfig: { moduleName: 'project' },
    component: Profile,
    exact: true,
    requiredPermissions: { group: 'people', entity: 'users', action: 'view_profile' }
  },
  {
    path: '/profile/:id/projects/new',
    sidebarConfig: { routePrefix: '/profile', path: '/profile' },
    text: 'Profile',
    component: Profile,
    accessConfig: { moduleName: 'project' },
    exact: true,
    requiredPermissions: [
      { group: 'people', entity: 'users', action: 'view_profile' },
      { group: 'projects', entity: 'general', action: 'manage_projects' }
    ]
  },
  {
    path: '/profile/:id/projects/timeline/:id',
    component: ProjectTimeline,
    accessConfig: { moduleName: 'project' },
    requiredPermissions: { group: 'projects', entity: 'general', action: 'manage_projects' }
  },
  {
    path: '/ams',
    sidebarConfig: { routePrefix: '/ams', path: '/ams' },
    text: 'AMS',
    icon: 'ams',
    atLeastOne: true,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'ams_permission' }
  },
  {
    path: '/ams/overview',
    component: AmsOverview,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'overview' }
  },
  {
    path: '/ams/shifts',
    component: AmsShiftsTab,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'shift_management' }
  },
  {
    path: '/ams/shifts/user-shifts',
    component: AmsUserShiftsTab,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'shift_management' }
  },
  {
    path: '/ams/my-calendar',
    component: AmsMyCalendar,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'team_calendar' }
  },
  {
    path: '/ams/reports',
    component: AmsReports,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'reports' }
  },
  {
    path: '/ams/reports/download-history',
    component: AmsDownloadHistoryTab,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'reports' }
  },
  {
    path: '/ams/mac-binding',
    component: AmsMacBinding,
    accessConfig: { moduleName: 'ams' },
    exact: true,
    requiredPermissions: { group: 'ams', entity: 'general', action: 'mac_binding' }
  },
  {
    path: '/workflow-categories',
    sidebarConfig: { routePrefix: '/workflow-categories', path: '/workflow-categories' },
    accessConfig: { moduleName: 'workflow' },
    icon: 'workflow_categories',
    text: 'Workflows',
    component: WorkFlowCategoryListings,
    exact: true,
    notSidebar: false,
    atLeastOne: true,
    requiredPermissions: [
      { group: 'workflows', entity: 'general', action: 'workflow' },
      { group: 'workflows', entity: 'general', action: 'all_tasks' }
    ]
  },
  {
    path: '/all-tasks',
    sidebarConfig: { routePrefix: '/all-tasks', path: '/all-tasks' },
    accessConfig: { moduleName: 'workflow' },
    component: TaskTriggers,
    notSidebar: false,
    exact: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'all_tasks' }
  },
  {
    path: '/all-tasks/:id/',
    accessConfig: { moduleName: 'workflow' },
    component: UserTask,
    exact: true,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: [
      { group: 'workflows', entity: 'general', action: 'all_tasks' },
      { group: 'people', entity: 'users', action: 'block_and_offboard' }
    ]
  },
  {
    path: '/workflow-categories/:id',
    accessConfig: { moduleName: 'workflow' },
    component: WorkflowCategoryDetail,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/new',
    accessConfig: { moduleName: 'workflow' },
    component: NewWorkflowCategory,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/edit/:id',
    accessConfig: { moduleName: 'workflow' },
    component: EditWorkflowCategory,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/new',
    accessConfig: { moduleName: 'workflow' },
    component: NewWorkflow,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/edit/:id',
    accessConfig: { moduleName: 'workflow' },
    component: EditWorkflow,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/new',
    accessConfig: { moduleName: 'workflow' },
    component: NewWorkflowTasksform,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/create',
    accessConfig: { moduleName: 'workflow' },
    component: NewWorkflowTasksform,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks',
    accessConfig: { moduleName: 'workflow' },
    component: WorkflowAllTasks,
    exact: true,
    notSidebar: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/edit/:id',
    accessConfig: { moduleName: 'workflow' },
    component: EditWorkflowTasksform,
    exact: true,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/:id/user-tasks',
    accessConfig: { moduleName: 'workflow' },
    component: TaskTriggers,
    exact: true,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/:id/user-tasks/:id/',
    accessConfig: { moduleName: 'workflow' },
    component: TaskDetailPage,
    exact: true,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: { group: 'workflows', entity: 'general', action: 'workflow' }
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/:id',
    accessConfig: { moduleName: 'workflow' },
    component: ShowWorkflowTasksform,
    exact: true,
    notSidebar: true,
    atLeastOne: true
  },
  {
    path: '/workflow-categories/:id/workflows/:id/tasks/:id/user-tasks/:id/attachments/:id/',
    accessConfig: { moduleName: 'workflow' },
    component: ShowTaskAttachment,
    exact: true,
    notSidebar: true,
    atLeastOne: true
  },
  {
    path: '/leaves/',
    sidebarConfig: { routePrefix: '/leaves', path: '/leaves' },
    icon: 'leaves',
    text: 'Leaves',
    accessConfig: { moduleName: 'leave' },
    exact: true,
    component: Leaves,
    atLeastOne: true,
    children: [
      { path: '/leaves/approvals' },
      { path: '/leaves/summary' },
      { path: '/leaves/policy' },
      { path: '/leaves/manual-adjustment' },
      { path: '/leaves/holidays' }
    ],
    requiredPermissions: [
      { group: 'leaves', entity: 'general', action: 'view_leave_approvals' },
      { group: 'leaves', entity: 'general', action: 'admin_view_all_leaves' },
      { group: 'leaves', entity: 'leave_policies', action: 'view_leave_policies' },
      { group: 'leaves', entity: 'general', action: 'hr_manual_adjustments' },
      { group: 'leaves', entity: 'holidays', action: 'view_holidays' }
    ]
  },
  {
    path: '/claims/',
    sidebarConfig: { routePrefix: '/claim', path: '/claims' },
    accessConfig: { moduleName: 'claim' },
    icon: 'claims',
    text: 'Claims',
    component: ClaimsPage,
    exact: true,
    children: [{ path: '/claims/approvals' }, { path: '/claims/management' }],
    atLeastOne: true,
    requiredPermissions: [
      { group: 'claims', entity: 'general', action: 'view_claim_approvals' },
      { group: 'claims', entity: 'general', action: 'admin_view_all_claims' }
    ]
  },
  {
    path: '/projects/',
    sidebarConfig: { routePrefix: '/project', path: '/projects' },
    accessConfig: { moduleName: 'project' },
    component: Projects,
    text: 'Projects',
    icon: 'projects',
    notSidebar: false,
    children: [
      { path: '/projects', indexRoute: true },
      { path: '/projects/manage-pseudo-profiles' },
      //TODO: Disabling communication allownance tab, will enable when required
      // {
      //   path: '/projects/invoice-management',
      //   accessConfig: { moduleName: 'project' },
      //   requiredPermissions: {
      //     group: 'projects',
      //     entity: 'communication_allowance',
      //     action: 'manage_communication_allowance'
      //   }
      // },
      { path: '/projects/claims-blacklist' }
    ],
    atLeastOne: true,
    requiredPermissions: [
      { group: 'projects', entity: 'general', action: 'pseudo_profiles_and_designations' },
      { group: 'projects', entity: 'invoice', action: 'invoice_data' },
      { group: 'projects', entity: 'general', action: 'update_project' }
    ]
  },
  //TODO: Disabling communication allownance tab, will enable when required
  // {
  //   path: '/projects/invoice-management/upload-invoice',
  //   accessConfig: { moduleName: 'project' },
  //   component: UploadInvoice,
  //   requiredPermissions: {
  //     group: 'projects',
  //     entity: 'communication_allowance',
  //     action: 'manage_communication_allowance'
  //   }
  // },
  {
    path: '/tickets/',
    sidebarConfig: { routePrefix: '/ticket', path: '/tickets' },
    icon: 'tickets',
    accessConfig: { moduleName: 'ticket' },
    text: 'Tickets',
    component: Tickets,
    children: [{ path: '/tickets', indexRoute: true }, { path: '/tickets/sent' }, { path: '/tickets/dashboard' }],
    exact: true,
    atLeastOne: true,
    requiredPermissions: [
      { group: 'tickets', entity: 'general', action: 'view_sent_tickets' },
      { group: 'tickets', entity: 'general', action: 'view_inbox_tickets' },
      { group: 'tickets', entity: 'general', action: 'ticket_dashboard' }
    ]
  },
  {
    path: '/ticket/:id',
    sidebarConfig: { routePrefix: '/ticket', path: '/tickets' },
    component: ShowTicket,
    accessConfig: { moduleName: 'ticket' },
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: [
      { group: 'tickets', entity: 'general', action: 'view_sent_tickets' },
      { group: 'tickets', entity: 'general', action: 'view_inbox_tickets' }
    ]
  },
  {
    path: '/inventory/',
    sidebarConfig: { routePrefix: '/inventory', path: '/inventory' },
    icon: 'inventory',
    text: 'Inventory',
    children: [
      { path: '/inventory', indexRoute: true },
      { path: '/inventory/:tab/overview' },
      { path: '/inventory/:tab/manage-category' },
      { path: '/inventory/:tab/manage-office' },
      { path: '/inventory/:tab/manage-vendor' },
      { path: '/inventory/:tab/manage-item' },
      { path: '/inventory/:tab/resell-item' },
      { path: '/inventory/:tab/repair-item' },
      { path: '/inventory/:tab/logs' }
    ],
    component: Items,
    atLeastOne: true,
    accessConfig: { moduleName: 'inventory' },
    requiredPermissions: [
      { group: 'moveable_inventory', entity: 'dashboard_overview', action: 'view_dashboard' },
      { group: 'moveable_inventory', entity: 'items', action: 'view_items' },
      { group: 'moveable_inventory', entity: 'categories', action: 'view_categories' },
      { group: 'moveable_inventory', entity: 'vendors', action: 'view_vendors' },
      { group: 'moveable_inventory', entity: 'offices', action: 'view_offices' },
      { group: 'moveable_inventory', entity: 'item_logs', action: 'view_item_logs' },
      { group: 'fixed_inventory', entity: 'dashboard_overview', action: 'view_dashboard' },
      { group: 'fixed_inventory', entity: 'items', action: 'view_items' },
      { group: 'fixed_inventory', entity: 'categories', action: 'view_categories' },
      { group: 'fixed_inventory', entity: 'vendors', action: 'view_vendors' },
      { group: 'fixed_inventory', entity: 'offices', action: 'view_offices' },
      { group: 'fixed_inventory', entity: 'item_logs', action: 'view_item_logs' }
    ]
  },
  {
    path: '/inventory/moveable/:tab/:id/sub_categories',
    component: SubCategoryPage,
    accessConfig: { moduleName: 'inventory' }
  },
  {
    path: '/inventory/fixed/:tab/:id/sub_categories',
    accessConfig: { moduleName: 'inventory' },
    component: SubCategoryPage
  },
  {
    path: '/inventory/fixed/manage-item/capitalize/:id',
    component: NewCapitalizeItem,
    accessConfig: { moduleName: 'inventory' },
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/moveable/manage-item/archived/new',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'moveable_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/fixed/manage-item/archived/new',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/moveable/manage-item/new',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'moveable_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/fixed/manage-item/new',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/fixed/manage-item/new-bulk-items',
    notSidebar: true,
    component: CreateBulkItems,
    accessConfig: { moduleName: 'inventory' },
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'create_item' }
  },
  {
    path: '/inventory/fixed/manage-item/archived',
    accessConfig: { moduleName: 'inventory' },
    component: ArchivedInventoryPage,
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'archive_item' }
  },
  {
    path: '/inventory/moveable/manage-item/archived',
    accessConfig: { moduleName: 'inventory' },
    component: ArchivedInventoryPage,
    requiredPermissions: { group: 'moveable_inventory', entity: 'items', action: 'archive_item' }
  },
  {
    path: '/inventory/moveable/details/:id',
    accessConfig: { moduleName: 'inventory' },
    component: ViewItemDetailPage,
    requiredPermissions: { group: 'moveable_inventory', entity: 'items', action: 'show_item' }
  },
  {
    path: '/inventory/fixed/details/:id',
    sidebarConfig: { routePrefix: '/item', path: '/item' },
    component: ViewItemDetailPage,
    notSidebar: true,
    atLeastOne: true,
    accessConfig: { moduleName: 'inventory' },
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'show_item' }
  },
  {
    path: '/inventory/moveable/manage-item/edit/:id',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'moveable_inventory', entity: 'items', action: 'update_item' }
  },
  {
    path: '/inventory/fixed/manage-item/edit/:id',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'fixed_inventory', entity: 'items', action: 'update_item' }
  },
  {
    path: '/inventory/fixed/manage-item/edit/:id',
    accessConfig: { moduleName: 'inventory' },
    component: NewTrackedItemPage,
    requiredPermissions: { group: 'inventory', entity: 'items', action: 'update_item' }
  },
  {
    path: '/reports',
    sidebarConfig: { routePrefix: '/report', path: '/reports' },
    icon: 'reports',
    text: 'Reports',
    component: Reports,
    children: [
      { path: '/reports', indexRoute: true },
      { path: '/reports/people' },
      { path: '/reports/workflow' },
      { path: '/reports/leave' },
      { path: '/reports/ticket' },
      { path: '/reports/feedback' },
      { path: '/reports/review' }
    ],
    atLeastOne: true,
    accessConfig: { moduleName: 'report' },
    requiredPermissions: [
      { group: 'reports', entity: 'general', action: 'overview_reports' },
      { group: 'reports', entity: 'general', action: 'leave_reports' },
      { group: 'reports', entity: 'general', action: 'ticket_reports' },
      { group: 'reports', entity: 'general', action: 'workflow_reports' },
      { group: 'people', entity: 'users', action: 'csv_download' }
    ]
  },
  {
    path: '/faqs',
    sidebarConfig: { routePrefix: '/faqs', path: '/faqs' },
    icon: 'faqs',
    text: 'FAQs',
    component: Question,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'faqs', entity: 'general', action: 'faqs_panel' }
  },
  {
    path: '/faqs/new',
    component: CreateQuestion,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'faqs', entity: 'general', action: 'faqs_panel' }
  },
  {
    path: '/faqs/:id',
    sidebarConfig: { routePrefix: '/faqs', path: '/faqs' },
    notSidebar: true,
    component: EditQuestion,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'faqs', entity: 'general', action: 'faqs_panel' }
  },
  {
    path: '/dnar',
    sidebarConfig: { routePrefix: '/dnar', path: '/dnar' },
    accessConfig: { moduleName: 'dnar' },
    icon: 'dnar',
    text: 'D-NAR',
    component: DnarsPage,
    children: [{ path: '/dnar/allocate-dnar' }, { path: '/dnar/management' }, { path: '/dnar/rewards' }],
    atLeastOne: true,
    requiredPermissions: [
      { group: 'dnars', entity: 'general', action: 'allocate_points_page' },
      { group: 'dnars', entity: 'general', action: 'dnars_management_tab' },
      { group: 'dnars', entity: 'general', action: 'define_redeem_policy_tab' }
    ]
  },
  {
    path: '/admin-panel/',
    sidebarConfig: { routePrefix: '/admin-panel', path: '/admin-panel' },
    accessConfig: { moduleName: 'dnar' },
    component: AdminPanelPage,
    children: [
      { path: '/admin-panel/', indexRoute: true },
      { path: '/admin-panel/central-bank-operations' },
      { path: '/admin-panel/pm-role-titles' }
    ],
    icon: 'admin_panel',
    text: 'D-NARS Admin Panel',
    atLeastOne: true,
    requiredPermissions: [
      { group: 'adminPanel', entity: 'pages', action: 'approve_dnar_requests' },
      { group: 'adminPanel', entity: 'pages', action: 'transfer_dnars' }
    ]
  },
  {
    path: '/calendar',
    sidebarConfig: { routePrefix: '/calendar', path: '/calendar' },
    icon: 'calendar',
    accessConfig: { moduleName: 'calendar' },
    text: 'Calendar',
    component: Calendar,
    notSidebar: false,
    requiredPermissions: { group: 'calendar', entity: 'general', action: 'view_calendar' }
  },
  {
    path: '/feedbacks',
    sidebarConfig: { routePrefix: '/feedbacks', path: '/feedbacks' },
    component: FeedbackPage,
    children: [{ path: '/feedbacks', indexRoute: true }, { path: '/feedbacks/show-feedback' }],
    text: 'Feedbacks',
    icon: 'feedbacks',
    notSidebar: false,
    accessConfig: { moduleName: 'feedback' },
    requiredPermissions: { group: 'feedback', entity: 'general', action: 'create_feedback' }
  },
  {
    path: '/subscriptions',
    sidebarConfig: { routePrefix: '/subscriptions', path: '/subscriptions' },
    accessConfig: { moduleName: 'subscription' },
    icon: 'subscription_panel',
    text: 'Subscription Panel',
    atLeastOne: true,
    exact: true,
    requiredPermissions: [
      { group: 'subscriptions', entity: 'general', action: 'verification_panel' },
      { group: 'subscriptions', entity: 'general', action: 'live_counter' },
      { group: 'subscriptions', entity: 'general', action: 'graphs_and_reports' }
    ]
  },
  {
    path: '/subscriptions/verification-panel',
    accessConfig: { moduleName: 'subscription' },
    component: SubscriptionPanel,
    exact: true,
    requiredPermissions: { group: 'subscriptions', entity: 'general', action: 'verification_panel' }
  },
  {
    path: '/subscriptions/live-counter',
    accessConfig: { moduleName: 'subscription' },
    component: ActiveSubscriptions,
    exact: true,
    requiredPermissions: { group: 'subscriptions', entity: 'general', action: 'live_counter' }
  },
  {
    path: '/subscriptions/reports',
    accessConfig: { moduleName: 'subscription' },
    exact: true,
    component: SubscriptionReports,
    requiredPermissions: { group: 'subscriptions', entity: 'general', action: 'graphs_and_reports' }
  },
  {
    path: '/roles',
    sidebarConfig: { routePrefix: '/role', path: '/roles' },
    icon: 'roles',
    text: 'Roles',
    component: RolesPage,
    accessConfig: { moduleName: 'role' },
    requiredPermissions: { group: 'roles', entity: 'general', action: 'view_roles' }
  },
  {
    path: '/roles/new',
    sidebarConfig: { routePrefix: '/role', path: '/roles' },
    component: NewRolesPage,
    accessConfig: { moduleName: 'role' },
    requiredPermissions: { group: 'roles', entity: 'general', action: 'create_role' }
  },
  {
    path: '/roles/edit/:id',
    sidebarConfig: { routePrefix: '/role', path: '/roles' },
    component: EditRolesPage,
    accessConfig: { moduleName: 'role' },
    requiredPermissions: { group: 'roles', entity: 'general', action: 'edit_role' }
  },
  {
    path: '/role/:id',
    component: EditRolesPage,
    accessConfig: { moduleName: 'role' },
    exact: true,
    requiredPermissions: { group: 'roles', entity: 'general', action: 'view_roles' }
  },
  {
    path: '/departments',
    sidebarConfig: { routePrefix: '/department', path: '/departments' },
    icon: 'departments',
    text: 'Departments',
    accessConfig: { moduleName: 'department' },
    component: DepartmentsPage,
    requiredPermissions: { group: 'departments', entity: 'general', action: 'manage_departments' }
  },
  {
    path: '/departments/new',
    component: DepartmentsPage,
    notSidebar: true,
    accessConfig: { moduleName: 'department' },
    requiredPermissions: { group: 'departments', entity: 'general', action: 'manage_departments' }
  },
  {
    path: '/departments/edit/:id',
    component: DepartmentsPage,
    notSidebar: true,
    accessConfig: { moduleName: 'department' },
    requiredPermissions: { group: 'departments', entity: 'general', action: 'manage_departments' }
  },
  {
    path: '/departments/rank-chart',
    component: DepartmentsPage,
    notSidebar: true,
    accessConfig: { moduleName: 'department' },
    requiredPermissions: { group: 'departments', entity: 'general', action: 'manage_departments' }
  },
  {
    path: '/settings',
    sidebarConfig: { routePrefix: '/settings', path: '/settings' },
    text: 'Settings',
    component: Settings,
    accessConfig: { alwaysEnabled: true },
    icon: 'settings',
    notSidebar: false
  },
  {
    path: '/settings/notifications',
    sidebarConfig: { routePrefix: '/settings', path: '/settings' },
    accessConfig: { alwaysEnabled: true },
    component: Settings
  },
  {
    path: '/settings/assistant-permissions',
    sidebarConfig: { routePrefix: '/settings', path: '/settings' },
    accessConfig: { alwaysEnabled: true },
    component: Settings
  },
  {
    path: '/hr-dashboard/pre-onboardings/new-bulk-users',
    notSidebar: true,
    component: CreateBulkUsers,
    accessConfig: { moduleName: 'pre_onboarding' },
    exact: true,
    requiredPermissions: { group: 'hrDashboard', entity: 'users', action: 'onboarding_tab' }
  },
  {
    path: '/hr-dashboard/pre-onboardings/new',
    component: OnboardingUser,
    exact: true,
    accessConfig: { moduleName: 'pre_onboarding' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/hr-dashboard/pre-onboardings/creation-logs',
    component: CreationLogs,
    exact: true,
    accessConfig: { moduleName: 'pre_onboarding' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/hr-dashboard/pre-onboardings/edit/oid/:id',
    component: EditOnboardingUser,
    accessConfig: { moduleName: 'pre_onboarding' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/hr-dashboard/pre-onboardings/move-to-normal-user/oid/:id',
    notSidebar: true,
    sidebarConfig: { routePrefix: '/hr-dashboard/pre-onboardings/edit', path: '/hr-dashboard/pre-onboardings/edit' },
    component: EditUser,
    accessConfig: { moduleName: 'pre_onboarding' },
    requiredPermissions: { group: 'people', entity: 'users', action: 'invite_people' }
  },
  {
    path: '/hr-dashboard/verify/oid/:id',
    notSidebar: true,
    component: EditUser,
    accessConfig: { moduleName: 'pre_onboarding' },
    atLeastOne: true,
    requiredPermissions: [
      { group: 'people', entity: 'users', action: 'edit_profile' },
      { group: 'people', entity: 'users', action: 'edit_profile_of_others' },
      { group: 'hrDashboard', entity: 'users', action: 'verification_tab' }
    ]
  },
  {
    path: '/project/new',
    accessConfig: { moduleName: 'project' },
    component: NewProjectPage,
    requiredPermissions: { group: 'projects', entity: 'general', action: 'manage_projects' }
  },
  {
    path: '/project/:id',
    sidebarConfig: { routePrefix: '/project', path: '/projects' },
    accessConfig: { moduleName: 'project' },
    component: ShowProjectPage,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: [{ group: 'projects', entity: 'general', action: 'manage_projects' }]
  },
  {
    path: '/project/edit/:id',
    sidebarConfig: { routePrefix: '/project', path: '/projects' },
    accessConfig: { moduleName: 'project' },
    component: EditProjectPage,
    notSidebar: true,
    atLeastOne: true,
    requiredPermissions: [{ group: 'projects', entity: 'general', action: 'update_project' }]
  },
  //TODO, Permission should be supported via all allowed values other than full as well
  {
    path: '/project/:id/history',
    sidebarConfig: { routePrefix: '/project', path: '/projects' },
    accessConfig: { moduleName: 'project' },
    component: ProjectHistoryPage,
    notSidebar: true,
    requiredPermissions: { group: 'projects', entity: 'members', action: 'manage_project_member' }
  },
  {
    path: '/user/project/:id/history',
    sidebarConfig: { routePrefix: '/user/project', path: '/projects' },
    accessConfig: { moduleName: 'project' },
    component: UserProjectHistoryPage,
    notSidebar: true,
    requiredPermissions: { group: 'projects', entity: 'members', action: 'manage_project_member' }
  },

  {
    path: '/application-settings',
    sidebarConfig: { routePrefix: '/application-settings', path: '/application-settings' },
    icon: 'application_settings',
    text: 'Admin Setting',
    accessConfig: { alwaysEnabled: true },
    atLeastOne: true,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'project_settings' },
      { group: 'policies', entity: 'general', action: 'ticket_settings' },
      { group: 'policies', entity: 'general', action: 'claim_settings' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' },
      { group: 'policies', entity: 'general', action: 'dnars_settings' },
      { group: 'policies', entity: 'general', action: 'office_settings' },
      { group: 'policies', entity: 'general', action: 'company_settings' },
      { group: 'policies', entity: 'general', action: 'inventory_settings' },
      { group: 'policies', entity: 'general', action: 'leave_admin_settings' },
      { group: 'policies', entity: 'general', action: 'time_tracker_settings' },
      { group: 'policies', entity: 'general', action: 'review_setting' },
      { group: 'policies', entity: 'general', action: 'dynamic_forms' },
      { group: 'policies', entity: 'general', action: 'slack_announcement_settings' },
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' }
    ]
  },
  {
    path: '/application-settings/dynamic-forms',
    accessConfig: { alwaysEnabled: true },
    component: DynamicFormSettings,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'dynamic_forms' }
  },
  {
    path: '/application-settings/dynamic-forms/new',
    accessConfig: { alwaysEnabled: true },
    component: NewDynamicForm,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'dynamic_forms' }
  },
  {
    path: '/application-settings/dynamic-forms/edit/:id',
    accessConfig: { alwaysEnabled: true },
    component: EditDynamicForm,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'dynamic_forms' }
  },
  {
    path: '/application-settings/dynamic-forms/:id/:id/:id/import/:id',
    accessConfig: { alwaysEnabled: true },
    component: ImportDynamicForm,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'dynamic_forms' }
  },
  {
    path: '/application-settings/document-settings',
    accessConfig: { alwaysEnabled: true },
    component: IdentityDocumentSettings,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'identity_document_settings' }
  },
  {
    path: '/application-settings/document-settings/identity-document-settings',
    accessConfig: { alwaysEnabled: true },
    component: IdentityDocuments,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'identity_document_settings' }
  },
  {
    path: '/application-settings/projects',
    component: ProjectSettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'project_settings' }
  },
  {
    path: '/application-settings/inventory',
    component: InventorySettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'inventory_settings' }
  },
  {
    path: '/application-settings/leaves',
    component: LeavesSettingsAdmin,
    children: [
      { path: '/application-settings/leaves' },
      { path: '/application-settings/leaves/sandwich_policy' },
      { path: '/application-settings/leaves/rules/auto_apply_policies' }
    ],
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'leave_admin_settings' }
  },
  {
    path: '/application-settings/leaves/rules/auto_apply_policies/new',
    accessConfig: { alwaysEnabled: true },
    component: NewAutoLeaveApplyRuleForm,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'leave_admin_settings' }
  },
  {
    path: '/application-settings/leaves/rules/auto_apply_policies/:id/edit',
    component: EditAutoLeaveApplyRule,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'leave_admin_settings' }
  },
  {
    path: '/application-settings/d-nars',
    component: DnarsSettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'dnars_settings' }
  },
  {
    path: '/application-settings/ha-ops',
    component: HROperationsSettings,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
  },
  {
    path: '/application-settings/ha-ops/edit-settings',
    component: EditHROperationSettings,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
  },
  {
    path: '/application-settings/ha-ops/probation-settings',
    accessConfig: { alwaysEnabled: true },
    component: EditHROperationSettings,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
  },
  {
    path: '/application-settings/ha-ops/pre-onboarding-settings',
    accessConfig: { alwaysEnabled: true },
    component: PreOnboardingSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' },
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' }
    ]
  },
  {
    path: '/application-settings/ha-ops/pre-onboarding-settings/manage-invite',
    accessConfig: { alwaysEnabled: true },
    component: PreOnboardingSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/pre-onboarding-settings/manage-pages',
    accessConfig: { alwaysEnabled: true },
    component: PreOnboardingSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/pre-onboarding-settings/manage-pages/new',
    accessConfig: { alwaysEnabled: true },
    component: PreOnboardingSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/pre-onboarding-settings/manage-pages/edit/:id',
    accessConfig: { alwaysEnabled: true },
    component: PreOnboardingSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'onboarding_setting_tab' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/review-settings',
    accessConfig: { alwaysEnabled: true },
    component: ReviewSettingsAdmin,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'review_setting' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/review-settings/create',
    component: ReviewSettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'review_setting' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/review-settings/edit/:id',
    component: ReviewSettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'review_setting' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/announcement-settings',
    accessConfig: { moduleName: 'slack_announcement' },
    component: AnnouncementSettings,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'slack_announcement_settings' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/announcement-settings/new',
    accessConfig: { moduleName: 'slack_announcement' },
    component: NewSlackAnnouncementSetting,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'slack_announcement_settings' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/announcement-settings/edit/:id',
    accessConfig: { moduleName: 'slack_announcement' },
    component: EditSlackAnnouncementSetting,
    requiredPermissions: [
      { group: 'policies', entity: 'general', action: 'slack_announcement_settings' },
      { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
    ]
  },
  {
    path: '/application-settings/ha-ops/email-list-settings',
    component: EmailListSettings,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
  },
  {
    path: '/application-settings/office-mgmt/',
    component: OfficeSettingsAdmin,
    children: [
      { path: '/application-settings/office-mgmt/', indexRoute: true },
      { path: '/application-settings/office-mgmt/create' },
      { path: '/application-settings/office-mgmt/edit/:id' }
    ],
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'office_settings' }
  },
  {
    path: '/application-settings/business-units/:action(create)?',
    component: BusinessUnitSettingsAdmin,
    children: [
      { path: '/application-settings/business-units/', indexRoute: true },
      { path: '/application-settings/business-units/create' }
    ],
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'hr_operations_settings' }
  },
  {
    path: '/application-settings/company',
    component: CompanySettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'company_settings' }
  },
  {
    path: '/application-settings/time-mgmt',
    component: TimeTrackerSettingsAdmin,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'time_tracker_settings' }
  },
  {
    path: '/application-settings/tickets',
    component: TicketSettings,
    children: [{ path: '/application-settings/tickets' }, { path: '/application-settings/tickets/categories' }],
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'ticket_settings' }
  },
  {
    path: '/application-settings/tickets/categories/:id',
    exact: true,
    accessConfig: { alwaysEnabled: true },
    component: TicketSetting,
    requiredPermissions: { group: 'policies', entity: 'general', action: 'ticket_settings' }
  },
  {
    path: '/application-settings/tickets/email',
    component: UpdateTicketEmail,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'ticket_settings' }
  },
  {
    path: '/application-settings/claims',
    component: ClaimsSettingsHome,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'claim_settings' }
  },
  {
    path: '/application-settings/claims/new',
    component: NewClaimSettingForm,
    accessConfig: { alwaysEnabled: true },
    requiredPermissions: { group: 'policies', entity: 'general', action: 'claim_settings' }
  },
  {
    path: '/integrations',
    sidebarConfig: { routePrefix: '/integrations', path: '/integrations' },
    icon: 'integrations',
    text: 'Integrations',
    component: IntegrationsPage,
    accessConfig: { moduleName: 'integrations' },
    requiredPermissions: { group: 'integrations', entity: 'general', action: 'manage_integrations' }
  },
  { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
]

export default appRoutes
